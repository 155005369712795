<template>
  <div id="app">
    <div id="nav">
      <a @click="currentComponents = 0" href="javascript:void(0);">图片</a>|
      <a @click="currentComponents = 1" href="javascript:void(0);">日志</a>
    </div>
    <PictureAdmin v-show="currentComponents === 0" />
  </div>
</template>

<script>
import PictureAdmin from "@/components/PictureAdmin.vue";

export default {
  components: {
    PictureAdmin,
  },
  data() {
    return {
      currentComponents: 0,
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  max-width: 90%;
  margin: 0 auto;
}

#nav {
  padding: 10px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
  text-decoration: none;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
