<template>
  <div class="about">
    <div id="add" v-show="adding">
      <p>
        <label>图片名称: </label
        ><input v-model="newPicture.name" type="input" />
      </p>
      <p>
        <label>图片描述: </label
        ><input v-model="newPicture.description" type="input" />
      </p>
      <p>
        <label>图片url: </label><input v-model="newPicture.url" type="input" />
      </p>
    </div>
    <div id="modify" v-show="modifying">
      <p>
        <label>图片名称: </label
        ><input v-model="selectedPicture.name" type="input" />
      </p>
      <p>
        <label>图片描述: </label
        ><input v-model="selectedPicture.description" type="input" />
      </p>
      <p>
        <label>图片url: </label
        ><input v-model="selectedPicture.url" type="input" />
      </p>
      <button
        v-show="adding === false && modifying === true"
        @click="modifyPicture()"
      >
        确认修改
      </button>
      <button
        v-show="adding === false && modifying === true"
        @click="modifying = false"
      >
        取消修改
      </button>
    </div>
    <div>
      <button
        v-show="adding === false && modifying === false"
        @click="adding = true"
      >
        添加
      </button>
      <button
        v-show="adding === true && modifying == false"
        @click="addPicture(true)"
      >
        确认添加
      </button>
      <button
        v-show="adding === true && modifying == false"
        @click="addPicture(false)"
      >
        取消
      </button>
    </div>
    <div>
      <div v-for="pic in pictures" :key="pic.uid">
        <img class="picture" :src="pic.url" :alt="pic.description" />
        <p>uid:{{ pic.uid }} name:{{ pic.name }}</p>

        <button
          v-show="adding === false && modifying === false"
          @click="prepareModify(pic)"
        >
          修改
        </button>
        <button
          v-show="adding === false && modifying === false"
          @click="deletePicture(pic)"
        >
          删除
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.getPictures();
  },
  data() {
    return {
      pictures: [],
      newPicture: {
        name: "",
        description: "",
        url: "",
      },
      selectedPicture: {
        name: "",
        description: "",
        url: "",
      },
      adding: false,
      modifying: false,
    };
  },
  methods: {
    getPictures() {
      this.$axios({
        method: "GET",
        url: "/picture/getPictures",
      }).then((res) => {
        this.pictures = res.data;
      });
    },
    addPicture(confirm) {
      this.adding = false;
      if (!confirm) {
        this.newPicture = {
          name: "",
          description: "",
          url: "",
        };
        return;
      }
      if (!this.checkInfo()) return alert("信息不完整");
      let uid = 0;
      if (this.pictures.length > 0) {
        uid = this.pictures[this.pictures.length - 1].uid + 1;
      }
      this.$axios({
        method: "POST",
        url: "/picture/addPicture",
        data: {
          uid: uid,
          name: this.newPicture.name,
          description: this.newPicture.description,
          url: this.newPicture.url,
        },
      }).then((res) => {
        if (res.data) {
          this.newPicture = {
            name: "",
            description: "",
            url: "",
          };
          this.getPictures();
        }
      });
    },
    prepareModify(pic) {
      this.modifying = true;
      this.selectedPicture.uid = pic.uid;
      this.selectedPicture.name = pic.name;
      this.selectedPicture.description = pic.deletePicture;
      this.selectedPicture.url = pic.url;
    },
    modifyPicture() {
      this.modifying = false;
      this.$axios({
        method: "POST",
        url: "/picture/modifyPicture",
        data: this.selectedPicture,
      }).then((res) => {
        if (res.data) {
          this.selectedPicture = {
            name: "",
            description: "",
            url: "",
          };
          this.getPictures();
        }
      });
    },
    deletePicture(pic) {
      let picName = prompt("请输入图片的名字：" + pic.name, "");
      if (picName == pic.name) {
        this.$axios({
          method: "POST",
          url: "/picture/deletePicture",
          data: { uid: pic.uid },
        }).then((res) => {
          if (res.data) {
            this.getPictures();
          }
        });
      }
    },
    checkInfo() {
      return this.newPicture.name != "" && this.newPicture.url != "";
    },
  },
};
</script>

<style>
.picture {
  vertical-align: middle;
  height: 100px;
}

div {
  margin: 5px;
}
</style>